body html {
  overflow: auto;

  margin: 0;
  padding: 0;
}

body {
  background-color: #081b29;

  color: white;
}

li p {
  letter-spacing: 1px;
  text-align: justify;
}

/* add custom font using file */

@font-face {
  font-family: "raftwork";
  src: url("./raftwork.otf");
}

@font-face {
  font-family: "queenmedium";
  src: url("./queenmedium.ttf");
}
@font-face {
  font-family: "binomabold";
  src: url("./binomabold.ttf");
}
@font-face {
  font-family: "BinomaRegular";
  src: url("./BinomaRegular.ttf");
}
@font-face {
  font-family: "number";
  src: url("./number.otf");
}

@font-face {
  font-family: "kingsman";
  src: url("./kingsman.ttf");
} /* not so cool*/

h1 {
  font-family: "BinomaRegular";
  font-weight: bold;
}
h1 .name {
  font-family: "binomabold";
  font-weight: bold;
  letter-spacing: 3px;
}
h1 .about {
  font-family: "binomabold";
  font-weight: bolder;
  letter-spacing: 3px;
}
.num {
  font-family: "queenmedium";
  letter-spacing: 3px;
  height: 100%;

  line-height: 50px;
}
h1 .stack {
  background: linear-gradient(180deg, #00e6e6, #00e6e6);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: "BinomaRegular";

  font-weight: bold;
  letter-spacing: -1px;
  -webkit-text-fill-color: transparent;
}

button {
  font-family: "binomabold";
}

.cursor {
  color: white;
}

.box {
  width: 25px;

  height: 25px;
  border-radius: 50%;
  background: #00e6e6;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #081b29; /* or any other color you prefer */
}

::-webkit-scrollbar-thumb {
  background-color: #00e6e6; /* Color for the scrollbar thumb */
  border-radius: 6px;
  border: 3px solid #081b29; /* Match the track color */
}
