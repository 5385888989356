@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    overflow: hidden;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .scroll-fade-in {
    opacity: 0;
    animation: fadeIn 0.6s ease-out forwards;
  }
  
  @media print {
    .no-print { display: none; }
    * { print-color-adjust: exact; }
  }